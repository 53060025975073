import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  ISurvey,
  ISurveyAnswer,
  ISurveyQuestion,
  IQuestionWithConditionalRecord,
} from "../../../../../@types/Surveys";
import { FixedTranslationsLanguageCodes } from "../../../../../@types/Translations";
import { Button } from "../../../../../components/Button";
import { ProgressBar } from "../../../../../components/ProgressBar";
import { IS_MOBILE_APP } from "../../../../../constants/localStorageKeys";
import { useDynamicsurveys } from "../../../../../hooks/useDynamicSurveys";
import { surveysService } from "../../../../../services/surveysService";
import { translationService } from "../../../../../services/translationsService";
import { parseSurveyTranslationResponseToDisplay } from "../../../../../utils/translationsUtils";
import { DateQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/DateQuestionDisplay";
import { FiveQuestionsAboutSupOxDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/FiveQuestionsAboutSupOxDisplay";
import { InformativeQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/InformativeQuestionDisplay";
import { MultiSelectQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/MultiSelectQuestionDisplay";
import { SingleSelectQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/SingleSelectQuestionDisplay";
import { SliderQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/SliderQuestionDisplay";
import { TextQuestionDisplay } from "../../../CreateSurvey/Components/SurveyBuilder/Components/TextQuestionDisplay";
import {
  SurveyDescription,
  SubmitContainer,
  SectionIterationButton,
  FormAnswerContainer,
  QuestionsAnswerContainer,
  spinCss,
  buttonCss,
  DisplayQuestion,
  FormInfoContainer,
  FormTitle,
  ProgressBarContainer,
  InfoContainer,
} from "./AnswerSurvey.styles";

export function AnswerSurvey() {
  const [selectedQuestionMinimumIndex, setSelectedQuestionMinimumIndex] =
    useState<number>(-1);

  const [selectedQuestionIndex, setSelectedQuestionIndex] =
    useState<number>(-1);

  const [survey, setSurvey] = useState<ISurvey>();
  const [isFiveQuestionsValidated, setIsFiveQuestionsValidated] =
    useState<boolean>(false);

  const [canModifySingleSelect, setCanModifySingleSelect] =
    useState<boolean>(true);

  const [questionIdListToDisplay, setQuestionIdListToDisplay] = useState<
    IQuestionWithConditionalRecord[]
  >([]);
  const [displaySurveyQuestions, setDisplaySurveyQuestions] =
    useState<ISurveyQuestion[]>();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTranslations, setLoadingTranslations] = useState<boolean>(true);
  const [leftPosition, setLeftPosition] = useState(0);

  const [displayProgressBar, setDisplayProgressBar] = useState<boolean>(true);
  const [answeredPercentage, setAnsweredPercentage] = useState<number>(0);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [userSurveyId, setUserSurveyId] = useState<string>();
  const {
    surveyAnswerArray,
    setValueSurveyAnswerArray,
    questionWithConditionalList,
    setQuestionWithConditionalList,
    updateSurveyAnswerArray,
  } = useDynamicsurveys();

  const [searchParams] = useSearchParams();

  const { t, i18n } = useTranslation();

  const handleTabClick = useCallback((e: KeyboardEvent) => {
    if (e.code === "Tab") {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleTabClick, false);

    const userSurveyId = window.location.pathname.split("/").pop();
    setUserSurveyId(userSurveyId);

    const token = searchParams.get("token");
    if (token) {
      const tokenJson = `{"tokenType":"Bearer","idToken":"${token}"}`;
      window.localStorage.setItem("tokenResponse", tokenJson);
      window.localStorage.setItem(IS_MOBILE_APP, "true");
    }
    i18n.changeLanguage();

    if (userSurveyId)
      (async () => {
        const { data, status } =
          await surveysService.getSurveyByUserSurveyIdMobile(userSurveyId);
        if (status >= 200 && status < 300) {
          setSurvey(data);
          setUserSurveyId(userSurveyId);
          if (data?.description?.replace(/ /g, "") === "") {
            setSelectedQuestionMinimumIndex(0);
            setSelectedQuestionIndex(0);
          }
        }
        setLoading(false);
      })();

    translationService
      .getSurveysFixedTranslationsByLanguageCode(
        i18n.language as FixedTranslationsLanguageCodes
      )
      .then((translations) => {
        if (!translations.data) return;
        const resourcesToAdd = parseSurveyTranslationResponseToDisplay(
          translations.data
        );
        i18n.addResourceBundle(i18n.language, "translation", resourcesToAdd);
        setLoadingTranslations(false);
      });

    return () => {
      window.removeEventListener("keydown", handleTabClick, false);
    };
  }, []);

  useEffect(() => {
    let questionCount = 0;
    const updatedSurveyAnswersArray: ISurveyAnswer[] = [];
    const firstQuestionWithConditionalList: IQuestionWithConditionalRecord[] =
      [];
    survey?.sections.forEach((section) => {
      section.questions.forEach((question) => {
        questionCount += 1;
        if (question.id) {
          updatedSurveyAnswersArray.push({
            surveyQuestionId: question.id,
            answerValue: "",
          });

          if (question.type === "SingleSelect") {
            question.options?.forEach((option) => {
              if (option.nextQuestionId) {
                if (
                  firstQuestionWithConditionalList.find(
                    (questionWithconditional) =>
                      questionWithconditional.questionId === question.id
                  ) === undefined
                ) {
                  firstQuestionWithConditionalList.push({
                    questionId: question.id,
                    jumpTo: "",
                  });
                }
              }
            });
          }
        }
      });
    });

    setQuestionWithConditionalList(firstQuestionWithConditionalList);
    setValueSurveyAnswerArray(updatedSurveyAnswersArray);
    setDisplayProgressBar(questionCount > 1);
  }, [survey]);

  const validateQuestionAnswer = () => {
    if (selectedQuestionIndex !== -1) {
      if (displaySurveyQuestions) {
        if (
          displaySurveyQuestions[selectedQuestionIndex]?.type === "Informative"
        ) {
          return true;
        }
        if (
          displaySurveyQuestions[selectedQuestionIndex]?.type !==
          "FiveQuestionsAboutSupOx"
        ) {
          return (
            surveyAnswerArray?.find(
              (surveyAnswer) =>
                surveyAnswer.surveyQuestionId ===
                displaySurveyQuestions[selectedQuestionIndex]?.id
            )?.answerValue !== ""
          );
        }
        return isFiveQuestionsValidated;
      }
    }
    return true;
  };

  const getAnsweredPercentage = () => {
    const questionsAnswered =
      selectedQuestionIndex !== -1
        ? surveyAnswerArray.findIndex(
            (surveyAnswer) =>
              surveyAnswer.surveyQuestionId ===
              questionIdListToDisplay[selectedQuestionIndex].questionId
          )
        : 0;

    if (questionsAnswered)
      setAnsweredPercentage(
        Math.round((questionsAnswered * 100) / surveyAnswerArray.length)
      );
  };

  const getQuestionIdToJumpAccordingToAnswerFromSurvey = (
    answer: ISurveyAnswer
  ): string => {
    let valueToReturn = "";
    survey?.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.id === answer.surveyQuestionId) {
          question.options?.forEach((option) => {
            if (
              answer.surveyQuestionOptionIds?.find(
                (surveyQuestionOptionId) => surveyQuestionOptionId === option.id
              )
            ) {
              valueToReturn = option.nextQuestionId ?? "";
            }
          });
        }
      });
    });
    return valueToReturn;
  };

  const handleQuestionAnimation = (moveQuestion: "forward" | "back") => {
    const windowWidth = window.innerWidth;
    if (selectedQuestionIndex !== -1) {
      switch (true) {
        case windowWidth < 769:
          if (moveQuestion === "forward") {
            setLeftPosition(leftPosition - window.innerWidth + 20);
          } else {
            setLeftPosition(leftPosition + window.innerWidth - 20);
          }
          break;
        case windowWidth > 768 && windowWidth < 1681:
          if (moveQuestion === "forward") {
            setLeftPosition(leftPosition - 0.8 * window.innerWidth);
          } else {
            setLeftPosition(leftPosition + 0.8 * window.innerWidth);
          }
          break;

        default:
          if (moveQuestion === "forward") {
            setLeftPosition(leftPosition - 0.5 * window.innerWidth);
          } else {
            setLeftPosition(leftPosition + 0.5 * window.innerWidth);
          }
          break;
      }
    }
  };

  const handleWindowResize = () => {
    const windowWidth = window.innerWidth;
    if (selectedQuestionIndex !== -1) {
      if (windowWidth < 769)
        setLeftPosition(0 - selectedQuestionIndex * (window.innerWidth - 20));
      if (windowWidth > 768 && windowWidth < 1681)
        setLeftPosition(0 - selectedQuestionIndex * (0.8 * window.innerWidth));
      if (windowWidth > 1680)
        setLeftPosition(0 - selectedQuestionIndex * (0.5 * window.innerWidth));
    }
  };

  window.addEventListener("resize", (e) => {
    e.stopPropagation();
    handleWindowResize();
  });

  const handleNextPageClick = () => {
    const newSelectedSectionIndex = selectedQuestionIndex + 1;
    setSelectedQuestionIndex(newSelectedSectionIndex);
    setCanModifySingleSelect(true);
    handleQuestionAnimation("forward");
  };

  const handlePreviousPageClick = () => {
    const newSelectedSectionIndex = selectedQuestionIndex - 1;
    setSelectedQuestionIndex(newSelectedSectionIndex);
    if (selectedQuestionIndex !== 0) handleQuestionAnimation("back");
  };

  const getQuestionIdListToDisplayFromConditionalJump = () => {
    const questionIdListToDisplay: IQuestionWithConditionalRecord[] = [];
    let skip = false;
    let changeSkipId = "";

    surveyAnswerArray.forEach((surveyAnswer, surveyAnswerIndex) => {
      if (changeSkipId === surveyAnswer.surveyQuestionId) {
        skip = false;
      }
      if (!skip) {
        const questionHasConditional = questionWithConditionalList.find(
          (questionWithconditional) =>
            questionWithconditional.questionId === surveyAnswer.surveyQuestionId
        );
        if (questionHasConditional) {
          const nextId = questionHasConditional.jumpTo;
          questionIdListToDisplay.push({
            questionId: surveyAnswer.surveyQuestionId,
            jumpTo: nextId,
          });
          skip = true;
          changeSkipId = nextId;
        } else if (surveyAnswerIndex + 1 < surveyAnswerArray.length) {
          let question: ISurveyQuestion = {
            id: "string",
            order: 0,
            text: "string",
            description: "string",
            type: "Text",
          };
          survey?.sections.forEach((surveySection) => {
            const questionToFind = surveySection.questions.find(
              (surveyQuestion) =>
                surveyQuestion.id ===
                surveyAnswerArray[surveyAnswerIndex].surveyQuestionId
            );
            if (questionToFind) {
              question = questionToFind;
            }
          });
          if (question?.nextQuestionId) {
            questionIdListToDisplay.push({
              questionId: surveyAnswer.surveyQuestionId,
              jumpTo: question?.nextQuestionId,
            });
            skip = true;
            changeSkipId = question?.nextQuestionId;
          } else {
            questionIdListToDisplay.push({
              questionId: surveyAnswer.surveyQuestionId,
              jumpTo: surveyAnswerArray[surveyAnswerIndex + 1].surveyQuestionId,
            });
          }
        } else {
          questionIdListToDisplay.push({
            questionId: surveyAnswer.surveyQuestionId,
            jumpTo: "",
          });
        }
      }
    });
    setQuestionIdListToDisplay(questionIdListToDisplay);
  };

  useEffect(() => {
    const updateQuestionWithConditionalList = [...questionWithConditionalList];
    let resetJumpingLogic = false;
    questionWithConditionalList?.forEach(
      (questionWithConditional, questionWithConditionalIndex) => {
        const currentAnswer = surveyAnswerArray.find(
          (answer) =>
            answer.surveyQuestionId === questionWithConditional.questionId
        );
        if (currentAnswer) {
          const currentAnswerJump =
            getQuestionIdToJumpAccordingToAnswerFromSurvey(currentAnswer);

          if (currentAnswer.answerValue !== "") {
            if (resetJumpingLogic) {
              updateQuestionWithConditionalList[
                questionWithConditionalIndex
              ].jumpTo = "";
            } else if (currentAnswerJump !== "") {
              updateQuestionWithConditionalList[
                questionWithConditionalIndex
              ].jumpTo = currentAnswerJump;
            } else if (currentAnswerJump === "") {
              const nextQuestionId =
                surveyAnswerArray[
                  surveyAnswerArray.findIndex(
                    (answer) =>
                      answer.surveyQuestionId === currentAnswer.surveyQuestionId
                  ) + 1
                ].surveyQuestionId;
              updateQuestionWithConditionalList[
                questionWithConditionalIndex
              ].jumpTo = nextQuestionId;
            }
          } else {
            updateQuestionWithConditionalList[
              questionWithConditionalIndex
            ].jumpTo = "";

            if (
              displaySurveyQuestions?.find(
                (question) => question.id === currentAnswer.surveyQuestionId
              )
            ) {
              resetJumpingLogic = true;
            }
          }
        }
      }
    );
    setQuestionWithConditionalList(updateQuestionWithConditionalList);
    getQuestionIdListToDisplayFromConditionalJump();
  }, [surveyAnswerArray]);

  const checkIfSingleSelectIsLastQuestion = (): boolean => {
    let result = false;
    const lastDisplayQuestion = displaySurveyQuestions?.pop();
    if (lastDisplayQuestion?.type === "SingleSelect") {
      lastDisplayQuestion.options?.forEach((option) => {
        if (option.nextQuestionId === undefined) {
          result = true;
        }
      });
    }
    return result;
  };

  const updateDisplaySurveyArray = () => {
    const updateDisplaySurveysQuestions: ISurveyQuestion[] = [];

    survey?.sections.forEach((section) => {
      section.questions.forEach((question) => {
        if (
          questionIdListToDisplay.find(
            (questionIdToDisplay) =>
              questionIdToDisplay.questionId === question.id
          )
        ) {
          updateDisplaySurveysQuestions.push(question);
        }
      });
    });
    setDisplaySurveyQuestions(updateDisplaySurveysQuestions);
    if (
      validateQuestionAnswer() &&
      displaySurveyQuestions &&
      selectedQuestionIndex !== -1 &&
      displaySurveyQuestions[selectedQuestionIndex]?.type === "SingleSelect" &&
      (selectedQuestionIndex < displaySurveyQuestions.length - 1 ||
        !checkIfSingleSelectIsLastQuestion())
    ) {
      setCanModifySingleSelect(false);
      setTimeout(() => {
        handleNextPageClick();
      }, 300);
    }
  };

  useEffect(() => {
    updateDisplaySurveyArray();
    getAnsweredPercentage();
  }, [questionIdListToDisplay]);

  useEffect(() => {
    if (
      displaySurveyQuestions &&
      selectedQuestionIndex !== -1 &&
      displaySurveyQuestions[selectedQuestionIndex]?.type === "Informative"
    ) {
      updateSurveyAnswerArray({
        surveyQuestionId:
          displaySurveyQuestions[selectedQuestionIndex]?.id ?? "",
        answerValue: "no_answer",
      });
    }
    getAnsweredPercentage();
  }, [selectedQuestionIndex]);

  const getAsnwerArray = () => {
    const updatedAnswerArray: ISurveyAnswer[] = [];
    surveyAnswerArray.forEach((answer) => {
      if (answer.answerValue !== "")
        updatedAnswerArray.push({
          surveyQuestionId: answer.surveyQuestionId,
          answerValue: answer.answerValue,
          surveyQuestionOptionIds: answer.surveyQuestionOptionIds,
        } as ISurveyAnswer);
    });

    return updatedAnswerArray;
  };

  const handleSubmit = async () => {
    if (userSurveyId) {
      const answerArray = getAsnwerArray();
      setSubmitLoading(true);
      const response = await surveysService.postUserSurveyAnswersMobile(
        userSurveyId,
        answerArray
      );
      if (response.status >= 200 && response.status < 300) {
        (window as { [key: string]: any }).sendPostStatusToFlutter(
          Boolean(true)
        );
      } else {
        (window as { [key: string]: any }).sendPostStatusToFlutter(
          Boolean(false)
        );
      }
    }
  };

  return loading || loadingTranslations ? (
    <Spin className={spinCss()} fullscreen size="large" />
  ) : (
    <FormAnswerContainer style={{ WebkitTapHighlightColor: "transparent" }}>
      {survey !== undefined &&
        displaySurveyQuestions &&
        displaySurveyQuestions.length > 0 && (
          <>
            {selectedQuestionIndex === -1 && (
              <FormInfoContainer>
                <InfoContainer>
                  <FormTitle>{survey.name}</FormTitle>
                  <SurveyDescription>{survey.description}</SurveyDescription>
                </InfoContainer>
                <Button
                  type="button"
                  label={`${t(
                    "survey_fixed_translations_survey_navigation_start"
                  )}`}
                  onClick={() => handleNextPageClick()}
                  className={buttonCss()}
                  disabled={!validateQuestionAnswer()}
                />
              </FormInfoContainer>
            )}

            {selectedQuestionIndex > -1 && (
              <QuestionsAnswerContainer style={{ left: leftPosition }}>
                {displaySurveyQuestions.map((question: ISurveyQuestion) => (
                  <React.Fragment key={`${question.id}`}>
                    <DisplayQuestion>
                      {(question.type === "Text" ||
                        question.type === "MultilineText") && (
                        <TextQuestionDisplay
                          key={`${question.id}`}
                          questionId={question.id}
                          title={question.text}
                          type={question.type}
                          description={question.description}
                        />
                      )}
                      {question.type === "SingleSelect" && (
                        <SingleSelectQuestionDisplay
                          key={`${question.id}`}
                          questionId={question.id}
                          title={question.text}
                          description={question.description}
                          options={question.options}
                          canModify={canModifySingleSelect}
                        />
                      )}
                      {question.type === "MultiSelect" && (
                        <MultiSelectQuestionDisplay
                          key={`${question.id}`}
                          questionId={question.id}
                          title={question.text}
                          description={question.description}
                          options={question.options}
                        />
                      )}
                      {(question.type === "Slider" ||
                        question.type === "SliderVas") &&
                        question.attributes && (
                          <SliderQuestionDisplay
                            verticalSliderHeight="100%"
                            key={`${question.id}`}
                            questionId={question.id}
                            title={question.text}
                            description={question.description}
                            options={question.options}
                            step={parseFloat(
                              question.attributes.find(
                                (attribute) => attribute?.name === "Step"
                              )?.value
                            )}
                            orientation={
                              question.attributes.find(
                                (attribute) => attribute?.name === "Orientation"
                              )?.value
                            }
                          />
                        )}

                      {question.type === "Date" && (
                        <DateQuestionDisplay
                          key={`${question.id}`}
                          questionId={question.id}
                          title={question.text}
                          description={question.description}
                        />
                      )}
                      {question.type === "FiveQuestionsAboutSupOx" && (
                        <FiveQuestionsAboutSupOxDisplay
                          key={`${question.id}`}
                          questionId={question.id}
                          setIsFiveQuestionsValidated={
                            setIsFiveQuestionsValidated
                          }
                        />
                      )}
                      {question.type === "Informative" && (
                        <InformativeQuestionDisplay
                          key={`${question.id}`}
                          HTMLText={question.text}
                        />
                      )}
                      <SubmitContainer>
                        {displayProgressBar && (
                          <ProgressBarContainer>
                            <ProgressBar percent={answeredPercentage} />
                          </ProgressBarContainer>
                        )}

                        <Button
                          isLoading={submitLoading}
                          type="button"
                          label={`${t(
                            "survey_fixed_translations_survey_navigation_next"
                          )}`}
                          onClick={() => {
                            if (
                              selectedQuestionIndex !==
                              displaySurveyQuestions.length - 1
                            ) {
                              handleNextPageClick();
                            } else {
                              handleSubmit();
                            }
                          }}
                          className={buttonCss()}
                          disabled={!validateQuestionAnswer()}
                        />

                        {selectedQuestionIndex >
                          selectedQuestionMinimumIndex && (
                          <SectionIterationButton
                            onClick={() => handlePreviousPageClick()}
                          >
                            {`${t(
                              "survey_fixed_translations_survey_navigation_previous"
                            )}`}
                          </SectionIterationButton>
                        )}
                      </SubmitContainer>
                    </DisplayQuestion>
                  </React.Fragment>
                ))}
              </QuestionsAnswerContainer>
            )}
          </>
        )}
      {survey === undefined && <div>There was an error getting the survey</div>}
    </FormAnswerContainer>
  );
}
