import { css, styled } from "../../styles";

export const TableContainer = styled("div", {
  overflowY: "overlay",
  maxHeight: "max-content",

  zIndex: 1000,

  borderRadius: "8px",

  width: "max-content",
  height: "max-content",
  minWidth: "100%",

  border: "1px solid #ECECEC",
  backgroundColor: "$white",

  boxSizing: "border-box",

  "&::-webkit-scrollbar": {
    width: "$4",
    height: "$4",
  },

  "&:hover": {
    "&::-webkit-scrollbar": {
      width: "$4",
      height: "$4",
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-track": {
      marginTop: "80px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "$gray-50",
      borderRadius: "10px",
    },
  },

  variants: {
    emptyState: {
      true: {
        height: "100%",
        position: "relative",
      },
    },

    isProtocols: {
      true: {
        th: {
          paddingTop: "25px",
        },

        div: {
          p: {
            marginTop: "70px",
          },
        },
      },
    },
  },
});

export const TableElement = styled("table", {
  width: "100%",
  background: "$white",

  borderRadius: "$1",
  borderSpacing: "0px",
  borderCollapse: "collapse",
  borderLeft: "none",
  borderRight: "none",

  thead: {
    borderBottom: "2px solid $gray-20",
  },

  tfoot: {
    height: "26px",
  },
});

export const ThSorted = styled("th", {
  background: "$white",
  color: "$charcoal",

  textAlign: "left",
  fontFamily: "$openSans",
  fontStyle: "normal",
  fontSize: "16px",

  fontWeight: "600",

  paddingTop: "$13",
  paddingBottom: "$9",

  position: "sticky",
  top: "0px",

  "&:first-child": {
    paddingLeft: "$14",
  },

  "&:last-child": {
    paddingRight: "$14",
  },
  cursor: "pointer",
  width: "auto",
});

export const ThUnsorted = styled("th", {
  background: "$white",
  color: "$charcoal",

  textAlign: "left",
  fontFamily: "$openSans",
  fontStyle: "normal",
  fontSize: "16px",

  fontWeight: "600",

  paddingTop: "$13",
  paddingBottom: "$9",

  position: "sticky",
  top: "0px",

  "&:first-child": {
    paddingLeft: "$14",
  },

  "&:last-child": {
    paddingRight: "$14",
  },
  width: "auto",
});

export const Tr = styled("tr", {
  height: "42px",

  fontFamily: "$openSans",

  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "400",

  color: "$charcoal",

  td: {
    "&:first-child": {
      paddingLeft: "$14",
    },
  },

  "&:nth-child(odd)": {
    backgroundColor: "$gray-20",
  },

  "&:hover": {
    backgroundColor: "rgba(233, 230, 244, .48) !important",
    transition: "background-color .1s",

    cursor: "pointer",

    td: {
      ".actionContainer": {
        visibility: "visible",
      },
    },
  },

  "&:active": {
    td: {
      ".actionContainer": {
        visibility: "visible",
      },
    },
  },

  "&:focus-visible": {
    outline: "none",
    backgroundColor: "rgba(233, 230, 244, .48)",
    boxShadow: "0px 0px 0px 2px #BDB4DD inset",
  },

  variants: {
    alert: {
      true: {
        "&:hover": {
          backgroundColor: "rgba(250, 209, 217, 0.14) !important",
        },
      },
    },
  },
});

export const EmptyStateContainer = styled("div", {
  justifySelf: "center",
  marginTop: "50px",
  marginBottom: "50px",
  p: {
    fontFamily: "$openSans",

    fontSize: "$text-lg",
    fontWeight: 400,

    color: "$charcoal",
  },
});

export const tooltipStyles = {
  borderRadius: "4px !important",
  fontSize: "12px",
  padding: "12px 16px",
  display: "flex",
  backgroundColor: "$charcoal",
};

export const ArrowContent = styled("div", {
  position: "relative",
  left: "-5px",
  display: "inline-block",
  width: "auto",
  height: "auto",
  padding: "5px 5px 5px 5px",
  borderRadius: "5px",
  "&:hover": {
    background: "#EDEDED",
  },
});

export const isSorted = css({
  background: "#EDEDED",
});

export const ArrowImage = styled("img", {
  border: "0",
  margin: "0",
  padding: "0px 0px 0px 5px",
});
